import * as React from 'react';

import { Layout } from 'src/layout';
import { Seo } from 'components/seo';
import { NotFoundView } from 'src/views/not-found-view';

const NotFoundPage = () => (
    <Layout>
        <Seo title="404: Not found" />
        <NotFoundView />
    </Layout>
);

export default NotFoundPage;
